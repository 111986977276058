

  import api from "@/api";
  import {Component, Vue,Ref} from "vue-property-decorator";
  import AbSelect from "@/components/AbSelect/index.vue";
  import {AlbumDto} from "@/api/appService";
  import {UserModule} from "@/store/modules/user";
  import {AppModule} from "@/store/modules/app";

  @Component({
      name: "albumPhotos",
      components: {
          AbSelect
      }
  })

    export default class AlbumPhotosList extends Vue {
      /*  data() {
            return {*/
                uploadData = {
                    albumId:undefined ,
                }
                uploadHeaders= {
                    contentType: 'multipart/form-data;',
                    Authorization:''
                }
                isShowUpdata= false;
                dialogImageUrl='';
                dialogVisible= false;
                pagesize= 20;
                page= 1;
                totalCount= 0;
                list= [];
                albumList: AlbumDto[] | undefined =[];
                queryForm= {
                    name: '',
                    skipCount: 0,
                    maxResultCount: 10,
                }
                form= {
                    visible: false,
                    submitting: false,
                    data: {
                        name: '',
                        summary: '',
                        id: 0
                    },
                    rules: {
                        name: [{
                            required: true,
                            message: '相册名称',
                            trigger: 'change'
                        },
                            {
                                max: 100,
                                message: '最多100个字符',
                                trigger: 'blur'
                            }
                        ]
                    }
                }
                defineImg= require('@/assets/images/none-cover.png')
       /*     }
        },*/
        created() {
            this.fetchData(1)
            this.fetchAlbumList()
            this.uploadHeaders.Authorization=`Bearer ${UserModule.getToken}`;
        }


            //  获取所有相册列表
            fetchAlbumList() {
                api.album.getAllAlbums({
                    skipCount: 0,
                    maxResultCount: 65535
                }).then(response => {
                    var total=response.totalCount as number;
                    if(total>0){

                        this.albumList = response.items;
                        this.uploadData.albumId =  this.albumList![0].id as any;
                    }
                })
            }
            //  获取相册列表
            fetchData(page:any) {
                if (!page) page = this.page;
                //根据实际请求中的页码更新分页器每页数据条数
                var sessionPagesize = this.$getActualPageSize()
                if (sessionPagesize > 0) {
                    this.pagesize = sessionPagesize
                }
                this.queryForm.skipCount = (page-1)*this.pagesize
                this.queryForm.maxResultCount = this.pagesize
                api.album.getAllAlbums(
                    this.queryForm
                ).then(response => {
                    this.list = response.items as any
                    this.totalCount = response.totalCount as any
                    //根据实际请求中的页码更新分页器页码
                    var actualPage = this.$getActualPageIndex()
                    if (actualPage > 0) {
                        this.page = actualPage
                    }
                })
            }
            current_change(e:number) {
                //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
                this.$clearHistorySearchParams()
                this.page = e
            }
            handleSizeChange(e:number) {
                //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
                this.$clearHistorySearchParams()
                this.pagesize = e
                this.fetchData(undefined)
            }
            // 查询
            onSearch() {
                this.fetchData(undefined)
            }
            // 创建相册
            handleCreateNewRole() {
                this.form.data.id=0;
                this.form.data.name = '';
                this.form.data.summary = '';
                this.form.visible = true;

            }
            // 保存新建相册
            handleRoleSave() {
                (this.$refs.roleForm as any).validate((valid:any) => {
                    if (valid) {
                        this.form.submitting = true
                        var fn
                        if (this.form.data.id) {
                            fn = api.album.update
                        } else {
                            fn = api.album.create
                        }
                        fn({body: this.form.data}).then(res => {
                            this.form.submitting = false
                            this.form.visible = false
                            this.$message.success("保存成功");
                            this.fetchData(undefined)
                            this.fetchAlbumList();
                        })
                        //  .fail(res => {
                        //    this.form.submitting = false
                        //    this.form.visible = false
                        //  })
                    }
                })
            }
            // 删除
            handleDelete(item:AlbumDto, index:number) {
                const id = item.id
                this.$confirm('您确认要删除此相册吗', '删除提示').then(() => {
                    api.album.delete({
                        id
                    }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        })
                        this.fetchData(undefined)
                    }).catch(error => {
                        console.log('delete catch')
                    })
                })
            }
            // 编辑
            handleEdit(item:AlbumDto, index:number) {
                const id = item.id
                api.album.get({
                    id
                }).then(res => {
                    this.form.data = res as any
                  console.log(this.form.data)
                    this.form.visible = true
                })
            }
            // 上传照片
            handleUpData() {
                this.isShowUpdata = true;
            }
            //  上传
            handleAvatarSuccess(res:any, file:any) {
                /*this.activityimagePath = res.result.url as any
                this.form.data.titleImageId = res.result.attachmentId*/
            }
            beforeAvatarUpload(file:any) {
            }
            handleRemove(file:any, fileList:any) {
                console.log(file, fileList);
                const id = file.response.result.id
                api.album.deletePhoto({
                    id
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                }).catch(error => {
                    console.log('delete catch')
                })
            }
            handlePictureCardPreview(file:any) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            }

    }

